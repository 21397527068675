import React from "react";
import Layout from "../component/layout";
import ServicesHomeComponentV2 from "../component/servicesHomeComponentV2";
//mport HomeImage from "../images/startup-development-home-image.png";
import WebsLogoSwiper from "../component/websLogoSwiper";
import ItExpandTeamComponent from "../component/Services/IT/ItExpandTeamComponent";
import ItProsConsComponent from "../component/Services/IT/ItProsConsComponent";
import Icon from "../images/card-icon.png";
import TechStackComponent from "../component/Services/techStackComponent";
import CustomDevelopmentVideoComponent from "../component/Services/CustomDevelopment/customDevelopmentVideoComponent";
import AccordionComponent from "../component/accordionComponent";
import FooterComponent from "../component/footerComponent";
import BookCallServiceComponent from "../component/bookCallServiceComponent";
import CardsComponent from "../component/CardsComponent";
import { graphql } from "gatsby";
import BorderCards from "../component/BorderCards";

const ServiceStartupsDevelopment = (props) => {
  const {
    data: {
      wpPage: {
        companyMissionSection: {
          companyMissionTitle,
          companyMissionDescription,
          companyMissionImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: HomeImage },
            },
          },
        },
        servicePageTeamSection: {
          servicePageTeamTitle,
          servicePageTeamDescription,
          servicePageTeamProduct,
          servicePageTeamBusinessAnalystTitle,
          servicePageTeamBusinessAnalystDescription,
          servicePageTeamProductTitle,
          servicePageTeamProductDescription,
          servicePageTeamDesignTitle,
          servicePageTeamDesignDescription,
          servicePageTeamWriterTitle,
          servicePageTeamWriterDescription,
          servicePageTeamImplementation,
          servicePageTeamManageTitle,
          servicePageTeamManagerDescription,
          servicePageTeamFrontendTitle,
          servicePageTeamFrontendDescription,
          servicePageTeamBackendTitle,
          servicePageTeamBackendDescription,
          servicePageTeamDevopsTitle,
          servicePageTeamDevopsDescription,
          servicePageTeamQaTitle,
          servicePageTeamQaDescription,
        },
        aboutUsHomeSection: {
          aboutUsHomeTitle,
          aboutUsHomeDescription,
          aboutUsHomeSolutionTitle,
          aboutUsHomeSolutionTextOne,
          aboutUsHomeSolutionTextTwo,
          aboutUsHomeSolutionTextThree,
        },
        comprehensiveOutsourcedSoftwere: {
          cosTitle,
          cosDescription,
          cosCardTitle1,
          cosCardTitle2,
          cosCardTitle3,
          cosCardTitle4,
          cosCardTitle5,
          cosCardDescription1,
          cosCardDescription2,
          cosCardDescription3,
          cosCardDescription4,
          cosCardDescription5,
          cosParagraph1,
          cosParagraph2,
        },
        careerPositionSection: {
          careerPositionTitle,
          careerPositionDescription,
          careerPositionFornend,
          careerPositionBackend,
          careerPositionDevops,
          careerPositionDesigner,
          careerPositionQa,
          careerPositionProjectManagement,
          careerPositionProjectManager,
          careerPositionSupport,
          careerPositionCostumerSupport,
          careerPositionContentManager,
          careerPositionSocialMediaMarketing,
          careerPositionMarketingManager,
          careerPositionTechnicalWriter,
          careerPositionText,
          careerPositionLocation,
        },

        processSection: {
          processSubtitle,
          processDescription,
          processAnalysisTitle,
          processAnalysisDescription,
          processProductTitle,
          processProductDescription,
          processDesigningTitle,
          processDesigningDescription,
          processAgileTitle,
          processAgileDescription,
          processLaunchingTitle,
          processLaunchingDescription,
        },
      },
    },
  } = props;
  const seo = {
    title: "Best Software Development for Startups | Motomtech",
    metaDesc:
      "You have ideas and we have the technical know-how and experience to bring your idea to reality. Give your idea its best shot with Motomtech’s custom software development.",
  };
  const arr1 = [
    {
      title: (
        <p
          className="it-pros-cons__wrapper-content-text-title"
          dangerouslySetInnerHTML={{ __html: servicePageTeamProduct }}
        />
      ),
      description: servicePageTeamBusinessAnalystTitle,
      number: "1",
    },

    {
      title: (
        <p
          className="it-pros-cons__wrapper-content-text-title"
          dangerouslySetInnerHTML={{
            __html: servicePageTeamBusinessAnalystDescription,
          }}
        />
      ),
      description: servicePageTeamProductTitle,
      number: "2",
    },

    {
      title: (
        <p
          className="it-pros-cons__wrapper-content-text-title"
          dangerouslySetInnerHTML={{
            __html: servicePageTeamProductDescription,
          }}
        />
      ),
      description: servicePageTeamDesignTitle,
      number: "3",
    },

    {
      title: (
        <p
          className="it-pros-cons__wrapper-content-text-title"
          dangerouslySetInnerHTML={{ __html: servicePageTeamDesignDescription }}
        />
      ),
      description: servicePageTeamWriterTitle,
      number: "4",
    },
    {
      title: (
        <p
          className="it-pros-cons__wrapper-content-text-title"
          dangerouslySetInnerHTML={{ __html: servicePageTeamWriterDescription }}
        />
      ),
      description: servicePageTeamImplementation,
      number: "5",
    },
  ];
  const arr2 = [
    {
      title: (
        <p
          className="it-pros-cons__wrapper-content-text-title"
          dangerouslySetInnerHTML={{ __html: servicePageTeamManageTitle }}
        />
      ),
      description: servicePageTeamManagerDescription,
      number: "6",
    },
    {
      title: (
        <p
          className="it-pros-cons__wrapper-content-text-title"
          dangerouslySetInnerHTML={{ __html: servicePageTeamFrontendTitle }}
        />
      ),
      description: servicePageTeamFrontendDescription,
      number: "7",
    },
    {
      title: (
        <p
          className="it-pros-cons__wrapper-content-text-title"
          dangerouslySetInnerHTML={{ __html: servicePageTeamBackendTitle }}
        />
      ),
      description: servicePageTeamBackendDescription,
      number: "8",
    },
    {
      title: (
        <p
          className="it-pros-cons__wrapper-content-text-title"
          dangerouslySetInnerHTML={{ __html: servicePageTeamDevopsTitle }}
        />
      ),
      description: servicePageTeamDevopsDescription,
      number: "9",
    },
    {
      title: (
        <p
          className="it-pros-cons__wrapper-content-text-title"
          dangerouslySetInnerHTML={{ __html: servicePageTeamQaTitle }}
        />
      ),
      description: servicePageTeamQaDescription,
      number: "10",
    },
  ];
  const cards = [
    {
      icon: Icon,
      title: careerPositionDescription,
      description: careerPositionFornend,
    },
    {
      icon: Icon,
      title: careerPositionBackend,
      description: careerPositionDevops,
    },
    {
      icon: Icon,
      title: careerPositionDesigner,
      description: careerPositionQa,
    },
    {
      icon: Icon,
      title: careerPositionProjectManagement,
      description: careerPositionProjectManager,
    },
    {
      icon: Icon,
      title: careerPositionSupport,
      description: careerPositionCostumerSupport,
    },
    {
      icon: Icon,
      title: careerPositionContentManager,
      description: careerPositionSocialMediaMarketing,
    },
    {
      icon: Icon,
      title: careerPositionMarketingManager,
      description: careerPositionTechnicalWriter,
    },
    {
      icon: Icon,
      title: careerPositionText,
      description: careerPositionLocation,
    },
  ];
  const questions1 = [
    {
      title: processAnalysisTitle,

      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: processAnalysisDescription.replace(/<br\s?\/?>/g, ""),
          }}
        />
      ),
      eventKey: 1,
    },
    {
      title: processProductTitle,

      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: processProductDescription.replace(/<br\s?\/?>/g, ""),
          }}
        />
      ),
      eventKey: 2,
    },
    {
      title: processDesigningTitle,

      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: processDesigningDescription.replace(/<br\s?\/?>/g, ""),
          }}
        />
      ),
      eventKey: 3,
    },
  ];

  const questions2 = [
    {
      title: processAgileTitle,

      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: processAgileDescription.replace(/<br\s?\/?>/g, ""),
          }}
        />
      ),
      eventKey: 4,
    },
    {
      title: processLaunchingTitle,

      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: processLaunchingDescription.replace(/<br\s?\/?>/g, ""),
          }}
        />
      ),
      eventKey: 5,
    },
  ];
  return (
    <Layout seo={seo} title={seo.title}>
      <ServicesHomeComponentV2
        homeTitle={companyMissionTitle}
        homeDescription={companyMissionDescription}
        homeImage={HomeImage}
        homeButton={"Request our services"}
      />
      <WebsLogoSwiper />
      <ItExpandTeamComponent
        title={aboutUsHomeTitle}
        description1={aboutUsHomeDescription}
        description2={aboutUsHomeSolutionTitle}
        description3={aboutUsHomeSolutionTextOne}
        description4={aboutUsHomeSolutionTextTwo}
        description5={aboutUsHomeSolutionTextThree}
      />
      <ItProsConsComponent
        title={servicePageTeamTitle}
        description={servicePageTeamDescription}
        arr1={arr1}
        arr2={arr2}
      />
      <div
        style={{
          background:
            "radial-gradient(80% 80% at 50% 50%, rgba(189, 217, 251, 0.4) 0%, #FFFFFF 100%)",
          marginBottom: "100px",
        }}
      >
        <p
          className="section-container-title"
          style={{ color: "#243062" }}
          dangerouslySetInnerHTML={{ __html: cosTitle }}
        />
        <BorderCards
          whiteBackground={true}
          borderCardsText1={cosCardTitle1}
          borderCardsText2={cosCardDescription1}
          borderCardsText3={cosCardTitle2}
          borderCardsText4={cosCardDescription2}
          borderCardsText5={cosCardTitle3}
          borderCardsText6={cosCardDescription3}
          borderCardsText7={cosCardTitle4}
          borderCardsText8={cosCardDescription4}
          borderCardsText9={cosCardTitle5}
          borderCardsText10={cosCardDescription5}
          borderCardsText11={cosParagraph1}
          borderCardsText12={cosParagraph2}
        />
        <p
          className="section-container-description"
          dangerouslySetInnerHTML={{ __html: cosDescription }}
        />
      </div>
      <CardsComponent
        title={careerPositionTitle}
        cards={cards}
        showCards={true}
      />
      <TechStackComponent />
      <CustomDevelopmentVideoComponent />
      <AccordionComponent
        title={processSubtitle}
        subtitle={processDescription}
        arr1={questions1}
        arr2={questions2}
      />
      <div
        style={{
          background:
            "linear-gradient(108.34deg, #09062D -2.63%, #36498C 100%)",
        }}
      >
        <BookCallServiceComponent
          bookCallServiceTitle={
            "Software Quality is done better with Motomtech"
          }
          bookCallServiceDescription={"Stop wasting time."}
          isFirstService={true}
          isSecondService={false}
        />
      </div>
      <FooterComponent />
    </Layout>
  );
};

export default ServiceStartupsDevelopment;
export const query = graphql`
  {
    wpPage(slug: { eq: "service-software-development-for-startups" }) {
      companyMissionSection {
        companyMissionTitle
        companyMissionDescription
        companyMissionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      aboutUsHomeSection {
        aboutUsHomeTitle
        aboutUsHomeDescription
        aboutUsHomeSolutionTitle
        aboutUsHomeSolutionTextOne
        aboutUsHomeSolutionTextTwo
        aboutUsHomeSolutionTextThree
      }
      servicePageTeamSection {
        servicePageTeamTitle
        servicePageTeamDescription
        servicePageTeamProduct
        servicePageTeamBusinessAnalystTitle
        servicePageTeamBusinessAnalystDescription
        servicePageTeamProductTitle
        servicePageTeamProductDescription
        servicePageTeamDesignTitle
        servicePageTeamDesignDescription
        servicePageTeamWriterTitle
        servicePageTeamWriterDescription
        servicePageTeamImplementation
        servicePageTeamManageTitle
        servicePageTeamManagerDescription
        servicePageTeamFrontendTitle
        servicePageTeamFrontendDescription
        servicePageTeamBackendTitle
        servicePageTeamBackendDescription
        servicePageTeamDevopsTitle
        servicePageTeamDevopsDescription
        servicePageTeamQaTitle
        servicePageTeamQaDescription
      }

      comprehensiveOutsourcedSoftwere {
        cosTitle
        cosDescription
        cosCardTitle1
        cosCardTitle2
        cosCardTitle3
        cosCardTitle4
        cosCardTitle5
        cosCardDescription1
        cosCardDescription2
        cosCardDescription3
        cosCardDescription4
        cosCardDescription5
        cosParagraph1
        cosParagraph2
      }
      careerPositionSection {
        careerPositionTitle
        careerPositionDescription
        careerPositionFornend
        careerPositionBackend
        careerPositionDevops
        careerPositionDesigner
        careerPositionQa
        careerPositionProjectManagement
        careerPositionProjectManager
        careerPositionSupport
        careerPositionCostumerSupport
        careerPositionContentManager
        careerPositionSocialMediaMarketing
        careerPositionMarketingManager
        careerPositionTechnicalWriter
        careerPositionText
        careerPositionLocation
      }

      processSection {
        processSubtitle
        processDescription
        processAnalysisTitle
        processAnalysisDescription
        processProductTitle
        processProductDescription
        processDesigningTitle
        processDesigningDescription
        processAgileTitle
        processAgileDescription
        processLaunchingTitle
        processLaunchingDescription
      }
    }
  }
`;
